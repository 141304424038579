import React from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function Home() {
    const query = useQuery();
    const token = query.get('token');

    return (
        <div>
            <h1>
                Bem-vindo!
            </h1>
            <div className={'description'}>
                Inicie agora escolhendo um serviço que se adeque a sua necessidade.
            </div>
            <ul className={'services-container'}>
                <li className={'service-card'}>
                    <h2>
                        Abertura da MEI
                    </h2>
                    <span>
                        Crie sua MEI, tenha CNPJ e passe a contar com os beneficios previcenciários.
                    </span>
                    <a
                        href={`https://formalizacao.bradesco.dicasmei.com.br/?token=${token}`}
                    >
                        <p>Iniciar</p>
                    </a>
                </li>
                <li className={'service-card'}>
                    <h2>
                        Extrato do Boleto para o MEI
                    </h2>
                    <span>
                    Gere o boleto DAS e mantenha seus impostos em dia!
                    </span>
                    <a
                        href={`https://boleto.bradesco.dicasmei.com.br/?token=${token}`}
                    >
                        <p>Iniciar</p>
                    </a>
                </li>
                {/*<li className={'service-card'}>*/}
                {/*    <h2>*/}
                {/*        Parcelar boleto DAS*/}
                {/*    </h2>*/}
                {/*    <span>*/}
                {/*        Parcele dívida do boleto do MEI.*/}
                {/*    </span>*/}
                {/*    <a*/}
                {/*        href={`https://parcelamentodas.bradesco.dicasmei.com.br/?token=${token}`}*/}
                {/*    >*/}
                {/*        <p>Iniciar</p>*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className={'service-card'}>
                    <h2>
                        Declaração anual de Faturamento do MEI (DASN)
                    </h2>
                    <span>
                    Faça sua declaração e mantenha sua empresa ativa!
                    </span>
                    <a
                        href={`https://dasnatraso.bradesco.dicasmei.com.br/?token=${token}`}
                    >
                        <p>Iniciar</p>
                    </a>
                </li>
                <li className={'service-card'}>
                    <h2>
                        Declarações DASN em Atraso
                    </h2>
                    <span>
                    Faça suas declarações atrasadas e fique em dias com as obrigações do MEI!
                    </span>
                    <a
                        href={`https://dasnatraso.bradesco.dicasmei.com.br/?token=${token}`}
                    >
                        <p>Iniciar</p>
                    </a>
                </li>
                <li className={'service-card'}>
                    <h2>
                        Emissão de Nota Fiscal
                    </h2>
                    <span>
                    Emita notas fiscais de serviços de forma simples.
                    </span>
                    <a
                        href={`https://notafiscal.bradesco.dicasmei.com.br/?token=${token}`}
                    >
                        <p>Iniciar</p>
                    </a>
                </li>
            </ul>
        </div>
    );
}
