import React from 'react';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

import './App.scss';

import Header from './Header';
import Home from './Home';

function App() {
    return (
        <div className="app-container">
            <Header />
            <Router>
                <Home />
            </Router>
        </div>
    );
}

export default App;
